@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

/* Scrollbar Start */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: #f7f8fa;
}

::-webkit-scrollbar-thumb {
  background: #d2d9e5;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Scrollbar End */

/* GLobal Start */
body {
  font-family: "Poppins", sans-serif;
}

/* GLobal Start */

/* Hidden Scrollbar Start */
.hidden-scroll {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.hidden-scroll::-webkit-scrollbar {
  display: none;
}

/* Hidden Scrollbar End */
